<template>
    <div class="legal-services">
        <div class="container">
            <div class="banner-static-services">
                <h4>
                    {{ $t('Welcome to the trademark registration service') }}
                </h4>
                <p>
                    {{ $t('Welcome trademark desc') }}
                </p>
            </div>
            <Contact />

            <div class="slider-steps">
                <div class="row">
                    <div class="col-md-6 mb-3">
                        <div class="title">{{ $t('Advantages of registering a trademark with Etmam') }}</div>
                       <p>
                        {{ $t('Immam provides trademark registration service, where we, on behalf of companies, institutions and individuals, register their distinctive trademarks with the Saudi Authority for Intellectual Property. Immam has a team of experienced experts who are committed to providing effective support and advice. Immam guarantees smooth procedures and fruitful results to its clients.') }}
                       </p>
                    </div>
                    <div class="col-md-6">
                        <div class="d-flex justify-content-end navigation-button ">
                            <span class="myPrev ">
                                <svg width="36" height="36" viewBox="0 0 36 36" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <rect width="36" height="36" rx="5" fill="#0a3041" />
                                    <path
                                        d="M13.6796 17.2929L17.9293 12.9999L16.9395 12L11 18L11.4949 18.4999L16.9395 24L17.9293 23.0001L13.6796 18.7071H25V17.2929H13.6796Z"
                                        fill="white" />
                                </svg>
                                <svg width="36" height="36" viewBox="0 0 36 36" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <rect x="35.5" y="35.5" width="35" height="35" rx="4.5"
                                        transform="rotate(180 35.5 35.5)" fill="white" stroke="#AFAFAF" />
                                    <path
                                        d="M22.3204 18.7071L18.0707 23.0001L19.0605 24L25 18L24.5051 17.5001L19.0605 12L18.0707 12.9999L22.3204 17.2929L11 17.2929L11 18.7071L22.3204 18.7071Z"
                                        fill="#AFAFAF" />
                                </svg>
                            </span>
                            <span class="myNext ">
                                <svg width="36" height="36" viewBox="0 0 36 36" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <rect width="36" height="36" rx="5" fill="#0a3041" />
                                    <path
                                        d="M13.6796 17.2929L17.9293 12.9999L16.9395 12L11 18L11.4949 18.4999L16.9395 24L17.9293 23.0001L13.6796 18.7071H25V17.2929H13.6796Z"
                                        fill="white" />
                                </svg>
                                <svg width="36" height="36" viewBox="0 0 36 36" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <rect x="35.5" y="35.5" width="35" height="35" rx="4.5"
                                        transform="rotate(180 35.5 35.5)" fill="white" stroke="#AFAFAF" />
                                    <path
                                        d="M22.3204 18.7071L18.0707 23.0001L19.0605 24L25 18L24.5051 17.5001L19.0605 12L18.0707 12.9999L22.3204 17.2929L11 17.2929L11 18.7071L22.3204 18.7071Z"
                                        fill="#AFAFAF" />
                                </svg>
                            </span>
                        </div>
                    </div>
                </div>
                <swiper class="swiper" :breakpoints="swiperOptions.breakpoints" :space-between="20" :autoplay="{
                            delay: 3000,
                        }" :navigation="{
                            enabled: true,
                            nextEl: '.myNext',
                            prevEl: '.myPrev',
                        }" :speed="1000">
                    <swiper-slide>
                        <div class="item">
                            <div class="index">1</div>
                            <div class="title">{{ $t('Accurate guidance and advice') }}</div>
                            <p>
                               {{ $t('Accurate guidance and advice desc') }}
                            </p>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="item">
                            <div class="index">2</div>
                            <div class="title">{{ $t('Accurate follow-up of transactions') }}</div>
                            <p>
                               {{ $t('Accurate follow-up of transactions desc') }}
                            </p>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="item">
                            <div class="index">3</div>
                            <div class="title">{{ $t('Providing support in case of conflict') }}</div>
                            <p>
                              {{ $t('Providing support in case of conflict desc') }}
                            </p>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="item">
                            <div class="index">4</div>
                            <div class="title">{{ $t('Speed ​​and efficiency') }}</div>
                            <p>
                               {{ $t('Speed ​​and efficiency desc') }}
                            </p>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="item">
                            <div class="index">5</div>
                            <div class="title">{{ $t('Providing legal protection') }}</div>
                            <p>
                             {{ $t('Providing legal protection desc') }}
                            </p>
                        </div>
                    </swiper-slide>
                </swiper>
            </div>
            <div class="banner-2">
                <div class="row">
                    <div class="col-md-6 mb-3">
                        <img class="w-100" src="@/assets/img/services-page/trade-banner-2.png" alt="">
                    </div>
                    <div class="col-md-6" style="display: grid;place-content: center;">
                        <div class="title">
                            {{ $t('Trademark registration') }}
                            <div style="font-size: 16px;">{{ $t('In the Kingdom of Saudi Arabia') }}</div>
                        </div>
                        <p>
                            {{ $t('Trademark registration desc') }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="how-help-you">
        <div class="title">
          {{$t('What we offer you !')}}
        </div>
        <p class="w8">
           {{ $t('Etmaam Services provides a wide range of services amounting to more than 500 services that establishments need within the Kingdom of Saudi Arabia. Whether through its team or through partnership with Etmam partners, it has a team and qualified partners to complete the services with high accuracy and in a short time.') }}
        </p>
        <div class="row what-we-offer mt-5">
          <div class="col-md-3 mb-3">
            <div class="item">
              <img src="@/assets/img/services-page/what-we-offer-7.png" alt="">
              <div class="title">{{ $t('Legal protection and confirmation of identity') }}</div>
              <p>
               {{$t(`Registering the trademark is considered a necessary step to protect the company's experience in the Kingdom of Saudi Arabia. Thanks to the legal protection provided by registration, a company is able to confirm its identity and prevent others from taking illegal advantage of it`)}}
              </p>
            </div>
          </div>
          <div class="col-md-3 mb-3">
            <div class="item">
              <img src="@/assets/img/services-page/what-we-offer-8.png" alt="">
              <div class="title">{{ $t('Excellence and building trust') }}</div>
              <p>
                {{ $t('Registering a trademark enables the company to stand out in the market and build trust among customers, as a registered trademark is considered a sign of quality and professionalism, which enhances its position in the minds of consumers.') }}
              </p>
            </div>
          </div>
          <div class="col-md-3 mb-3">
            <div class="item">
              <img src="@/assets/img/services-page/what-we-offer-9.png" alt="">
              <div class="title">{{ $t('Investment protection and scope expansion') }}</div>
              <p>
                {{ $t('By registering a trademark, the company protects its investments and avoids potential commercial risks. Registration also enables the company to expand its scope of work and expand into new markets inside and outside the Kingdom') }}
              </p>
            </div>
          </div>
          <div class="col-md-3 mb-3">
            <div class="item">
              <img src="@/assets/img/services-page/what-we-offer-10.png" alt="">
              <div class="title">{{ $t('Building reputation and interacting with customers') }}</div>
              <p>
               {{ $t('Registering a trademark contributes to building the company’s reputation and enhancing interaction with customers. The registered trademark reflects the company’s credibility and its responsiveness to the needs and aspirations of customers.') }}
              </p>
            </div>
          </div>
          
        </div>
     </div>
        </div>

    </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
    components: {
        Contact: defineAsyncComponent(() => import('./component/contact-services.vue')),
    },
    data() {
        return {
            swiperOptions: {
                breakpoints: {
                    300: { slidesPerView: 1, spaceBetween: 50 },
                    576: { slidesPerView: 2, spaceBetween: 50 },
                    767: { slidesPerView: 2, spaceBetween: 50 },
                    991: { slidesPerView: 3, spaceBetween: 20 },
                    1200: { slidesPerView: 3, spaceBetween: 20 },
                }
            },
        };
    },
    methods: {
    },
    mounted() {
    }

}
</script>